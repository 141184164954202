import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Firestore for storing blog data
import { getStorage } from 'firebase/storage'; // Firebase Storage for images

const firebaseConfig = {
    apiKey: "AIzaSyCSrbSK6AnV1-QeEOfOKMQ56H1ATWbnyvk",
    authDomain: "safehealth-d4f3f.firebaseapp.com",
    projectId: "safehealth-d4f3f",
    storageBucket: "safehealth-d4f3f.appspot.com",
    messagingSenderId: "163541464141",
    appId: "1:163541464141:web:fb8b0b9ae9a5fa3bdc9804",
    measurementId: "G-NED82R8FVJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app); // Initialize Storage

export { auth, db, storage };