import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import BlogPage from './BlogPage';
import Home from './Home';
import AboutUs from './AboutUs';
// import ContactUs from './ContactUs';
import AdminPanel from './AdminPanel';
import Footer from './Footer';
import Sidebar from './Sidebar';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BlogProvider } from './BlogContext';

import NutritionPage from './Blogs/NutritionPage';
import MentalHealthPage from './Blogs/mentalHealth';
import FitnessPage from './Blogs/fitness';
import MedicalCaseStudiesPage from './Blogs/medicalCase';
import WomensHealthPage from './Blogs/womenHealth';
import MensHealthPage from './Blogs/menHealth';
import EmergingTechPage from './Blogs/emerging-tech';
import HealthConditionsPage from './Blogs/healthcaretech';
import SexualHealthPage from './Blogs/sexualHealth';
import LifestyleDisorderPage from './Blogs/lifestyleDisorder';
import PublicHealthPage from './Blogs/publicHealth';
import ChildrensCarePage from './Blogs/childrencare';


import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
// import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import './App.css';
import SafeHealthLogo from './assets/SafeHealthLogo.png';
import SafeHealthLogo1 from './assets/SafeHealthLogo1.png';
import Article from './Article';

Modal.setAppElement('#root');

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const [modalIsOpen, setModalIsOpen] = useState(false);
    const [adminModalIsOpen, setAdminModalIsOpen] = useState(false);
    /*const [blogPosts, setBlogPosts] = useState(() => {
        const savedBlogs = localStorage.getItem('blogPosts');
        return savedBlogs ? JSON.parse(savedBlogs) : [];
    });*/
    // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [setIsSidebarOpen] = useState(false);

    const [isPopupVisible, setIsPopupVisible] = useState(true); // Track popup visibility

    // Listen to auth state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsLoggedIn(!!user);
        });
        return () => unsubscribe();
    }, []);

    // const handleLogin = () => {
    //     signInWithEmailAndPassword(auth, email, password)
    //         .then((userCredential) => {
    //             setIsLoggedIn(true);
    //             setModalIsOpen(false);
    //         })
    //         .catch(() => {
    //             alert('Email or password is incorrect. Please try again.');
    //         });
    // };

    // const handleLogout = () => {
    //     signOut(auth)
    //         .then(() => {
    //             setIsLoggedIn(false);
    //         })
    //         .catch((error) => {
    //             console.error('Error logging out:', error.message);
    //         });
    // };

    useEffect(() => {
        setIsAdminLoggedIn(JSON.parse(localStorage.getItem("adminAuth")) || false)
        // if (JSON.parse(localStorage.getItem("adminAuth"))) {
        //     if(window.location.href!="/admin"){
        //         window.location.href = "/admin";
        //     }
        // } else {
        //     window.location.href = "/";
        // }
    }, [])

    /*const addBlogPost = (newBlog) => {
        const updatedBlogs = [...blogPosts, newBlog];
        setBlogPosts(updatedBlogs);
        localStorage.setItem('blogPosts', JSON.stringify(updatedBlogs));
    };*/

    const handleAdminLogout = (e) => {
        if (isAdminLoggedIn) {
            e.preventDefault();
            try {
                axios.get('https://api.healthsafe.in/admin/admin-logout', { withCredentials: true }) // Adjust URL to match your backend endpoint
                    .then(response => {
                        if (response.status === 200 || response.status === 'OK') {
                            // navigate('/admin/admin-add-new-blog')
                            toast.success('Admin Logout successfully')
                            window.location.href = "/"
                            //console.log("response => ", response);
                            // clear enter data
                            setIsAdminLoggedIn(false);
                            localStorage.setItem("adminAuth", false)

                        } else toast.error("msg not sended")
                    })
                    .catch(error => toast.error(error))
            } catch (err) {
                toast.error(err);
                // console.log(err);
            }
        } else {
            toast.error("user should logged in")
        }
    }

    const handleAdminLogin = async (e) => {
        if (isLoggedIn) {  // only isadminlogin variable is uptodate not having isLoggedin variable uptodate 
            toast.info("first logout userid")
        } else {
            e.preventDefault();
            try {
                axios.post('https://api.healthsafe.in/admin/admin-login', { email, password }) // Adjust URL to match your backend endpoint
                    .then(response => {
                        if (response.status === 200 || response.status === 'OK') {
                            // navigate('/admin/admin-add-new-blog')
                            toast.success('Admin Login successfully')

                            //console.log("response are => ", response);
                            document.cookie = "jwt=" + response.data.token;
                            window.location.href = "/admin"

                            // clear enter data
                            setIsAdminLoggedIn(true);
                            localStorage.setItem("adminAuth", true)

                        } else toast.error("msg not sended")
                    })
                    .catch(error => toast.error(error))
            } catch (err) {
                toast.error(err);
                // console.log(err);
            }
        }
    }
    //console.log(JSON.parse(localStorage.getItem("adminAuth")));

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen); // Update state when sidebar is toggled
    };

    // Popup dismiss handler
    const handleDismissPopup = () => {
        setIsPopupVisible(false);
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <BlogProvider>
                <Router>
                    <div className="app-layout">

                        {/* Apply conditional class based on sidebar state */}
                        <div className="main-content">
                            <header>
                                <div className='header-main'>
                                    <div className="container navbar">
                                        <div className="logo">
                                            <Link to="/" className='logoImg'>
                                                {/* <img src={SafeHealthLogo1} alt='SH Logo'/> */}
                                                <img src={SafeHealthLogo} alt='Safe Health Logo' />

                                                <img src={SafeHealthLogo1} alt="Safe Health Text" className="logo2" id='logoText' />

                                            </Link>
                                        </div>
                                        <button className="menu-toggle" onClick={toggleMenu}>
                                            ☰
                                        </button>
                                        <nav className={`nav-links ${menuOpen ? "active" : ""}`}>
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="/about-us">About Us</Link></li>
                                                <li><a href="#contact-us">Contact Us</a></li>
                                                <li><Link to="/blogs">Blogs</Link></li>
                                                {isAdminLoggedIn ?
                                                    <><li><Link to="/admin">Admin Panel</Link></li></> :
                                                    <></>
                                                }
                                            </ul>

                                            {/* <div className="auth">
                                {!isLoggedIn ? (
                                    <>
                                        <button onClick={() => setModalIsOpen(true)}>Login</button>
                                        <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="login-modal">
                                            <h2>Login</h2> */}
                                            {!isLoggedIn ?
                                                <>
                                                    <div className='auth'>
                                                        {!isAdminLoggedIn ? (
                                                            <>
                                                                <button onClick={() => setAdminModalIsOpen(true)}>Login</button>
                                                                <Modal isOpen={adminModalIsOpen} onRequestClose={() => setAdminModalIsOpen(false)} className="login-modal">
                                                                    <h1>Welcome Back!</h1>
                                                                    <h2>Login to your account</h2>
                                                                    <br />
                                                                    <input
                                                                        type="email"
                                                                        placeholder="Email"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                    />
                                                                    <input
                                                                        type="password"
                                                                        placeholder="Password"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                    />
                                                                    {/* <button onClick={handleLogin}>Login</button>
                                            <button onClick={() => setModalIsOpen(false)}>Close</button>
                                        </Modal>
                                    </>
                                ) : (
                                    <button onClick={handleLogout}>Logout</button>
                                )}
                            </div>
                        </div>
                    <hr className="custom-hr"></hr></header> */}
                                                                    <button onClick={handleAdminLogin}>Login</button>
                                                                    <button onClick={() => setAdminModalIsOpen(false)}>Close</button>
                                                                </Modal>
                                                            </>
                                                        ) : (
                                                            <button onClick={(e) => handleAdminLogout(e)} style={{ marginRight: '50px', fontWeight: 'bolder' }}>Admin Logout</button>
                                                            // <button onClick={()=>setAdminModalIsOpen(true)}>Admin</button>
                                                        )}
                                                    </div>
                                                </> : <></>
                                            }
                                        </nav>
                                    </div>
                                    <hr /></div></header>


                            <main className="main-container">
                                <Sidebar onToggle={handleSidebarToggle} /> {/* Sidebar Component */}
                                <div className="content">
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/about-us" element={<AboutUs />} />
                                        {/* <Route path="/contact-us" element={<ContactUs />} /> */}
                                        <Route path="/blogs" element={<BlogPage blogPosts={[]} />} />
                                        <Route path="/admin" element={<AdminPanel auth={isAdminLoggedIn} />} />
                                        <Route path="/blogs/:id" element={<Article />} />
                                        {/* {isLoggedIn && <Route path="/admin" element={<AdminPanel />} />} */}

                                        {/* Sidebar Item Routes */}
                                        <Route path="/blogs/nutrition" element={<NutritionPage />} />
                                        <Route path="/blogs/mental-health" element={<MentalHealthPage />} />
                                        <Route path="/blogs/fitness" element={<FitnessPage />} />
                                        <Route path="/blogs/medical-case-studies" element={<MedicalCaseStudiesPage />} />
                                        <Route path="/blogs/womens-health" element={<WomensHealthPage />} />
                                        <Route path="/blogs/mens-health" element={<MensHealthPage />} />
                                        <Route path="/blogs/emerging-tech" element={<EmergingTechPage />} />
                                        <Route path="/blogs/health-conditions" element={<HealthConditionsPage />} />
                                        <Route path="/blogs/sexual-health" element={<SexualHealthPage />} />
                                        <Route path="/blogs/lifestyle-disorder" element={<LifestyleDisorderPage />} />
                                        <Route path="/blogs/public-health" element={<PublicHealthPage />} />
                                        <Route path="/blogs/childrens-care" element={<ChildrensCarePage />} />
                                        <Route path="/blogs/childrens-care" element={<ChildrensCarePage />} />
                                        {/* <Route path="/article" element={<Article />} /> */}
                                    </Routes>
                                </div>
                            </main>
                            {/* Popup for subscribe */}
                            {isPopupVisible && (
                                <div className="popup-container">
                                    <h2>WELCOME TO SAFE HEALTH!</h2><hr></hr>
                                    <p>
                                        Welcome to Safe Health! Your journey towards better health starts here. Subscribe now for daily tips, expert advice, and the latest updates to stay informed and empowered on your wellness journey!
                                    </p>
                                    <div className="popup-buttons">
                                        <button className="ignore-btn" onClick={handleDismissPopup}>No,Thanks</button>
                                        <button className="subscribe-btn" onClick={handleDismissPopup}><a href="#contact-us" className='subscribe-txt'>SUBSCRIBE</a></button>
                                    </div>
                                </div>
                            )}
                            {/* <hr className="custom-hr lower-content"></hr> */}
                            <div className="hr-sep"></div>
                            <Footer />

                        </div>
                    </div>
                </Router>
            </BlogProvider>
        </>
    );

}

export default App;
