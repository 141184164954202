// AdminBlog.js
import React, { useEffect, useState } from 'react';
import '../BlogPage.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AdminBlog = ({ setBlogInput }) => {
    const [blogs, setBlogs] = useState([]);

    const deleteThisBlog = (e, id) => {
        try {
            axios.delete(`https://api.healthsafe.in/blog/deleteBlog/${id}`) // Adjust URL to match your backend endpoint
                .then(response => {
                    if (response.status === 200 || response.status === 'OK') {
                        // navigate('/admin/admin-add-new-blog')
                        toast.success('Blog Deleted successfully')

                        // console.log("get all blogs response are => ", response);
                        setBlogs(response.data.blog)
                        getAllBlogs()
                        window.location.href = "/admin"
                    } else toast.error("no Data found")
                })
                .catch(error => toast.error(error))
        } catch (err) {
            toast.error(err);
            // console.log(err);
        }
    }

    const getAllBlogs = (e) => {
        // e.preventDefault();
        try {
            axios.get('https://api.healthsafe.in/blog/') // Adjust URL to match your backend endpoint
                .then(response => {
                    if (response.status === 200 || response.status === 'OK') {
                        // navigate('/admin/admin-add-new-blog')
                        // toast.success('All Blogs Get successfully')

                        // console.log("get all blogs response are => ", response);
                        setBlogs(response.data.blog)
                    } else toast.error("no Data found")
                })
                .catch(error => toast.error(error))
        } catch (err) {
            toast.error(err);
        }
    }

    useEffect(() => {
        getAllBlogs()
    }, [])
    // link: '/blog/manage-stress',
    return (

        <div className="blog-page">
            <div className="blog-grid">
                <div className="blog-post" id='btn-addnewblog' onClick={() => setBlogInput(true)} >
                    Add New Blog
                </div>
                {blogs?.slice().reverse().map((post, index) => (
                    <div key={index} className="blog-post" style={{ position: 'relative' }}>
                        <button className='deleteIconBtn' onClick={(event) => deleteThisBlog(event, post._id)}><i className="fa-solid fa-trash"></i></button>
                        <Link to={`/blogs/${post._id}`} key={post.id} className="blog-link">
                        <div className="blog-card">
                            <img src={post.blog_img} alt={post.title} />
                            <h4>{post.title}</h4>
                            <p className='sub-title'>{post.Sub_title}</p>
                            {/* <div dangerouslySetInnerHTML={{ __html: blog.Sub_title }} ></div> */}
                            <i><p className="blog-author">{post.writter}</p></i>
                            <i><p className="blog-date">{post.date.substring(0, 10)}</p></i>
                        </div>
                        </Link>
                        {/* <a href="#" className="read-more">Read More</a> */}
                    </div>
                ))}
            </div>
        </div>
    );
};




export default AdminBlog;

