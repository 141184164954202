import React, { useContext } from 'react';
import './BlogPage.css';
import { Link } from 'react-router-dom';
import { BlogContext } from '../BlogContext';

const BlogPage = () => {
  const { blogs } = useContext(BlogContext);

  const medicalCaseStudiesBlogs = blogs.filter(blog => blog.category === "Medical Case Studies");

  // const category = medicalCaseStudiesBlogs.length > 0 ? medicalCaseStudiesBlogs[0].category : 'General';

  return (
    <div className="blog-page">
      <h1>Medical Case Blogs</h1>
      <div className="blog-grid">
        {medicalCaseStudiesBlogs.length === 0 ?
          <p className="coming-soon">
            Coming Soon...! Explore our <Link to="/blogs" className="explore-link">other blogs</Link> in the meantime.
          </p> :
          <>
            {medicalCaseStudiesBlogs.map((post, index) => (
              <div key={index} className="blog-post">
                <Link to={`/blogs/${post._id}`} className="blog-link">
                  <div className="blog-card">
                    <img src={post.blog_img} alt={post.title} />
                    <h4>{post.title}</h4>
                    <p className='sub-title'>{post.Sub_title}</p>
                    {/* <div dangerouslySetInnerHTML={{ __html: blog.Sub_title }} ></div> */}
                    <i><p className="blog-author">{post.writter}</p></i>
                    <i><p className="blog-date">{post.date.substring(0, 10)}</p></i>
                  </div>
                </Link>
              </div>
            ))}
          </>
        }
      </div>
    </div>
  );
};
export default BlogPage;
