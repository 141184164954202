import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { Zoom, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

localStorage.setItem("adminAuth", localStorage.getItem("adminAuth") || false)
axios.defaults.baseURL = process.env.REACT_APP_BACKENDURL;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      transition={Zoom}
    />
  </React.StrictMode >
);

reportWebVitals();
