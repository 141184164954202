// Feating Blogs data

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);

    const getAllBlogs = () => {
        axios.get('https://api.healthsafe.in/blog/')
            .then(response => {
                if (response.status === 200) {
                    setBlogs(response.data.blog);
                } else {
                    toast.error("No Data found");
                }
            })
            .catch(error => toast.error(error.message));
    };

    useEffect(() => {
        getAllBlogs();
    }, []);

    return (
        <BlogContext.Provider value={{ blogs }}>
            {children}
        </BlogContext.Provider>
    );
};
