import React, { useContext } from 'react';
import './Home.css'
// import BlogImages from './assets/vaccine.jpg';
import { Link } from 'react-router-dom';
import { BlogContext } from './BlogContext';


const Home = () => {
  // Sample blog data
  const { blogs } = useContext(BlogContext);
  // console.log("blog ", blogs)

  // Slicing latest 3 blogs to show
  const latestBlogs = [...blogs]
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 3);

  //   {
  //     id: 1,
  //     title: 'Healthy Living: Tips for a Balanced Life',
  //     image: BlogImages, // Replace with actual image URL
  //     description: 'Learn about maintaining a balanced and healthy lifestyle with these tips.'
  //   },
  //   {
  //     id: 2,
  //     title: 'How to Improve Your Mental Health',
  //     image: BlogImages, // Replace with actual image URL
  //     description: 'Mental health is just as important as physical health.'
  //   },
  //   {
  //     id: 3,
  //     title: 'Fitness: How to Stay Active at Home',
  //     image: BlogImages, // Replace with actual image URL
  //     description: 'Discover easy ways to stay fit even when you can’t make it to the gym.'
  //   }
  // ];

  return (
    <>
      <div className="home">
        <div className="blog-section">
          <h2>This week's trending blogs</h2>
          <div className="blogs">
            {latestBlogs.map((blog) => (
              <Link to={`/blogs/${blog._id}`} key={blog.id} className="blog-link">
                <div className="blog-card">
                  <img src={blog.blog_img} alt={blog.title} />
                  <h4>{blog.title}</h4>
                  <p className='sub-title'>{blog.Sub_title}</p>
                  {/* <div dangerouslySetInnerHTML={{ __html: blog.Sub_title }} ></div> */}
                  <i><p className="blog-author">{blog.writter}</p></i>
                  <i><p className="blog-date">{blog.date.substring(0, 10)}</p></i>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* <div className="random-info">
        <h2>Did You Know?</h2>
        <p>Health is not just about the absence of disease, it's about complete physical, mental, and social well-being.</p>
        <p>Drinking water before meals can help with digestion and weight control.</p>
        <p>Regular exercise not only helps your physical health but also boosts your mood and mental clarity.</p>
      </div> */}
      </div >
    </>
  );
};

export default Home;
