// Article.js
// import React from 'react';
// import './Article.css';
// import vaccine from './assets/vaccine.jpg';

// const Header = () => (
//     <header className="header">
//         <h1>How Artificial Intelligence is Revolutionizing Personalized Medicine</h1>
//     </header>
// );

// const Section = ({ title, children }) => (
//     <section className="section">
//         <h2>{title}</h2>
//         {children}
//     </section>
// );


// const Article = () => (
//     <div className="article-page">
//          <img src={vaccine} className="blogImage header" />
//         <Header />
//         <p>By Dr. Satyam Pandey in MBBS</p>
//         <p>Oct 31, 2024</p>
//         <main className="content">
//             <Section title="Introduction">
//                 <p>In recent years, artiicial intelligence (AI) has emerged as a transformative force across various industries,
// and healthcare is no exception. One of the most exciting applications of AI is in the realm of personalized medicine—
// a ield dedicated to tailoring medical treatment to the individual characteristics of each patient. This blog explores how
// AI is revolutionizing personalized medicine, enhancing treatment eficacy, and reshaping the future of healthcare</p>
//             </Section>
//             <Section title="Understanding Personalized Medicine">
//                 <p>Personalized medicine involves customizing healthcare treatments based on individual differences in genetics,
// environment, and lifestyle. The goal is to move away from a one-size-its-all approach and provide targeted therapies
//  that maximize effectiveness and minimize side effects.</p>
//             </Section>
//             <Section title="AI's Role in Personalized Medicine">
//                 <h3>1. Genomic Analysis</h3>
//                 <p>AI algorithms can analyze complex genomic data to identify genetic variations associated with speciic diseases.
//  By processing vast amounts of genetic information, AI can pinpoint mutations that may inluence a patient's response
// to certain treatments. This leads to more precise and effective treatment plans.</p>

//                 <h3>2. Predictive Analytics</h3>
//                 <p>AI models use historical patient data to predict disease risk and progression. For instance, AI can analyze electronic
//  health records (EHRs) to identify patterns and predict which patients are at higher risk for developing chronic conditions
// . This proactive approach allows for earlier intervention and preventive measures.</p>

//                 <h3>3. Drug Development</h3>
//                 <p>The drug discovery process is often lengthy and costly. AI accelerates this process by analyzing data on how different
// compounds interact with biological targets. AI algorithms can predict which compounds are most likely to be effective,
//  reducing the time and cost involved in bringing new drugs to market.</p>

//                 <h3>4. Tailored Treatment Plans</h3>
//                 <p>AI-driven tools can help doctors create personalized treatment plans based on a patient's unique health proile.
//  For example, AI can analyze data from previous treatments and outcomes to recommend the most effective therapy
//  for a speciic patient. This personalized approach improves treatment outcomes and reduces trial-and-error in
//  selecting therapies.</p>

//                 <h3>5. Real-Time Monitoring</h3>
//                 <p>AI-powered wearable devices and mobile apps can continuously monitor patients' health metrics in real-time.
// This data can be used to adjust treatment plans dynamically, ensuring that interventions are always aligned with
// the patient's current condition.
// </p>
//             </Section>
//             <Section title="Real-World Examples">
//                 <h3>1. Oncology</h3>
//                 <p>In oncology, AI is used to analyze tumor genomics and recommend personalized chemotherapy regimens.
// For instance, IBM's Watson for Oncology has been employed to suggest treatment options based on analysis
// of medical literature and patient data.</p>

//                 <h3>2. Cardiology</h3>
//                 <p>AI systems like Google's DeepMind have shown promise in predicting cardiovascular events by analyzing retinal
// scans which can provide insights into heart health and potential risks before they manifest.
// </p>
//             </Section>
//             <Section title="Challenges and Future Directions">
//                 <p>While AI holds great promise, several challenges remain, including data privacy concerns, the need for high-quality
// datasets, and ensuring algorithmic fairness. Addressing these issues is crucial for the continued advancement of AI
// in personalized medicine.
// Looking ahead, the integration of AI with other emerging technologies like genomics and advanced imaging will further
// enhance the precision and effectiveness of personalized medicine. The ongoing research and development in this ield
//  will likely lead to even more groundbreaking innovations in the near future</p>
//             </Section>
//             <Section title="Conclusion">
//                 <p>Artiicial intelligence is undeniably reshaping the landscape of personalized medicine, offering new opportunities for
//  more precise, effective, and individualized healthcare. As AI technology continues to evolve, its role in medicine will
//  become increasingly central, driving forward the promise of truly personalized patient care.</p>
//             </Section>
//         </main>
//     </div>
// );

// export default Article;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const Article = () => {
//     const { title } = useParams(); // Get the title from the URL parameters
//     const [article, setArticle] = useState(null);

//     useEffect(() => {
//         const fetchArticle = async () => {
//             try {
//                 const response = await axios.get(`/api/blogs/${title}`); // Adjust URL to your backend endpoint
//                 if (response.status === 200) {
//                     setArticle(response.data.article);
//                 } else {
//                     toast.error('Article not found');
//                 }
//             } catch (error) {
//                 toast.error('Error fetching article');
//             }
//         };

//         fetchArticle();
//     }, [title]);

//     if (!article) return <p>Loading...</p>;

//     return (
//         <div className="article-page">
//             <h1>{article.title}</h1>
//             <img src={article.blog_img} alt={article.title} />
//             <p>{article.content}</p>
//             <i><p>By {article.writter}</p></i>
//             <i><p>Published on {article.date}</p></i>
//         </div>
//     );
// };

// export default Article;

// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';

// const Article = () => {
//   const { title } = useParams();
//   const [article, setArticle] = useState(null);
//   const [error, setError] = useState('');

//   useEffect(() => {
//     axios.get(`/blogs/${encodeURIComponent(title)}`)
//       .then(response => {
//         setArticle(response.data);
//         console.log("Blog here available",response);
//       })
//       .catch(error => console.error("Error fetching article:", error));
//   }, [title]);


//   if (!article) return <p>Loading...</p>;

//   return (
//     <div>
//       <h1>{article.title}</h1>
//       <p>{article.blog_img}</p>
//       <p>{article.Sub_title}</p>
//       <p>{article.writter}</p>
//       <p>{article.date}</p>
//       {/* Render other details like author, date, etc. */}
//     </div>
//   );
// };

// export default Article;


// frontend/src/components/Article.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Article.css';

const Article = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  //   useEffect(() => {
  //     const fetchBlog = async () => {
  //       const { data } = await axios.get(`http://localhost:5000/api/blogs/${id}`);
  //       setBlog(data);
  //     };
  //     fetchBlog();
  //   }, [id]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const { data } = await axios.get(`https://api.healthsafe.in/blog/${id}`);
        setBlog(data);
      } catch (error) {
        console.error(error);
        // console.error('Error fetching blog data:', error);
      }
    };
    fetchBlog();
  }, [id]);

  if (!blog) return <p>Loading...</p>;

  return (
    <>
    <div className='article'>
      {/* <h1>{blog.title}</h1>
      <p>{blog.Sub_title}</p>
      <p>{blog.writter}</p>
      <p>{blog.date}</p>
      <p>{blog.category}</p> */}
      <img src={blog.blog_img} alt={blog.title}  className='article-details'/>
      {/* <h1>{blog.title}</h1> */}
      {/* <h3>{blog.Sub_title}</h3> */}
      <div dangerouslySetInnerHTML={{ __html: blog.blog_description_1 }} className='article-details'></div>
    </div>
    </>
  );
};

export default Article;
