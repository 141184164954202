import React from 'react';
import { Instagram, Linkedin, Twitter } from 'lucide-react';
import './Footer.css'; // Import the CSS file
import SafeHealthLogo from './assets/SafeHealth_footer.png';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineMail } from "react-icons/ai";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer" id='contact-us'>
      <div className="footer-container">
        <div className="footer-column">
          <img src={SafeHealthLogo} alt="Safe Health Logo" className="footer-logo" />
        </div>

        <div className="footer-column">
          <h3 className="footer-title">Quick Links</h3>
          <ul className="footer-links">
            <li><a href="#contact-us" className="footer-link">Contact Us</a></li>
            <li><a href="#" className="footer-link">Privacy Policy</a></li>
            <li><a href="#" className="footer-link">Terms & Conditions</a></li>
            {/* <li><a href="/gifting" className="footer-link">Gifting Policy</a></li> */}
          </ul>
        </div>

        {/* <div className="footer-column">
          <h3 className="footer-title">Services</h3>
          <ul className="footer-links">
            <li><a href="/payment" className="footer-link">Online Payment & Refund Policy</a></li>
            <li><a href="/teleconsult" className="footer-link">Tele-consult</a></li>
          </ul>
        </div> */}
        <div>
          <h3 className="footer-title">Connect with us</h3>
          <ul className="footer-links">
            <li>
              <a href="mailto:support@healthsafe.in" className="email-link">
                <AiOutlineMail className="icon" />
                <span>
                  <p>support@healthsafe.in</p>
                </span>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <h3 className="footer-title">Follow us on </h3>
          <div className="footer-social-icons">
            <a href="https://www.instagram.com/safe_health_official?igsh=MWU3ODllNWJsODRheA==" className="footer-social-link" target="_blank"><Instagram className="icon" /></a>
            {/* <a href="#" className="footer-social-link" target="_blank"><Facebook className="icon" /></a> */}
            <a href="https://x.com/SafeHealth91891?t=hd939_3bJ7MY1J7hZK2GGA&s=09" className="footer-social-link" target="_blank"><Twitter className="icon" /></a>
            <a href="http://www.linkedin.com/in/safe-health-10b0b3333" className="footer-social-link" target="_blank"><Linkedin className="icon" /></a>
            <a href="https://whatsapp.com/channel/0029Vak6eYz6mYPOQFAf8J2D" className="footer-social-link" target="_blank" rel="noopener noreferrer"><FaWhatsapp className="icon" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        © {currentYear} Safe Health. All Rights Reserved.
      </div>
    </footer>
  );
}
