import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";

import nutritionIcon from "./assets/sidebar/nutrition.png";
import mentalHealthIcon from "./assets/sidebar/mental_health.png";
import fitnessIcon from "./assets/sidebar/fitness.png";
import caseStudiesIcon from "./assets/sidebar/medical_case.png";
import womensHealthIcon from "./assets/sidebar/women_healt.png";
import mensHealthIcon from "./assets/sidebar/men_health.png";
import techIcon from "./assets/sidebar/healthcare_tech.png";
import conditionsIcon from "./assets/sidebar/healthcare_tech.png";
import sexualHealthIcon from "./assets/sidebar/sexual_health.png";
import lifestyleIcon from "./assets/sidebar/lifestyle_disorder.png";
import publicHealthIcon from "./assets/sidebar/public_health.png";
import childrenCareIcon from "./assets/sidebar/children care.png";
import menu from "./assets/sidebar/menu.png";


function Sidebar() {
  const [isMinimized, setIsMinimized] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleMouseEnter = () => {
    setIsAnimating(true);
    setIsMinimized(false);
  };

  const handleMouseLeave = () => {
    setIsAnimating(true);
    setIsMinimized(true);
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const categories = [
    { title: "Children's Care", icon: childrenCareIcon, link: "/blogs/childrens-care" },
    { title: "Health Care Technologies", icon: techIcon, link: "/blogs/emerging-tech" },
    { title: "Fitness", icon: fitnessIcon, link: "/blogs/fitness" },
    { title: "Health Conditions", icon: conditionsIcon, link: "/blogs/health-conditions" },
    { title: "Lifestyle Disorder", icon: lifestyleIcon, link: "/blogs/lifestyle-disorder" },
    { title: "Men's Health", icon: mensHealthIcon, link: "/blogs/mens-health" },
    { title: "Mental Health", icon: mentalHealthIcon, link: "/blogs/mental-health" },
    { title: "Medical Case Studies", icon: caseStudiesIcon, link: "/blogs/medical-case-studies" },
    { title: "Nutrition", icon: nutritionIcon, link: "/blogs/nutrition" },
    { title: "Public Health & Safety", icon: publicHealthIcon, link: "/blogs/public-health" },
    { title: "Sexual Health", icon: sexualHealthIcon, link: "/blogs/sexual-health" },
    { title: "Women's Health", icon: womensHealthIcon, link: "/blogs/womens-health" },
  ];

  // Sort categories alphabetically by title
  const sortedCategories = categories.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div
      className={`sidebar ${isMinimized ? "minimized" : ""} ${isAnimating ? (isMinimized ? "sliding-out" : "sliding-in") : ""
        }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="sidebar-header">
      <img src={menu} alt="Category" className="sidebar-icon"  style={{height:'1.5rem'}}/>
        {!isMinimized && <h2 className="sidebar-title">CATEGORIES</h2>}
      </div>

      <ul className="sidebar-items">
        {sortedCategories.map((category, index) => (
          <Link to={category.link}>
            <li key={index} className="sidebar-item">

              <img src={category.icon} alt={category.title} className="sidebar-icon" />
              {!isMinimized && <span>{category.title}</span>}

            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;

//   return (
//     <>
//     <div
//       className={`sidebar ${isMinimized ? "minimized" : ""} ${
//         isAnimating ? (isMinimized ? "sliding-out" : "sliding-in") : ""
//       }`}
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//       onAnimationEnd={handleAnimationEnd}
//     >
//       <div className="sidebar-header">
//         {!isMinimized && <h2 className="sidebar-title">CATEGORIES</h2>}
//       </div>

//       <ul className="sidebar-items">
//         <li className="sidebar-item">
//           <Link to="/blogs/nutrition">
//             <img src={nutritionIcon} alt="Nutrition" className="sidebar-icon" />
//             {!isMinimized && <span>Nutrition</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/mental-health">
//             <img
//               src={mentalHealthIcon}
//               alt="Mental Health"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Mental Health</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/fitness">
//             <img src={fitnessIcon} alt="Fitness" className="sidebar-icon" />
//             {!isMinimized && <span>Fitness</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/medical-case-studies">
//             <img
//               src={caseStudiesIcon}
//               alt="Medical Case Studies"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Medical Case Studies</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/womens-health">
//             <img
//               src={womensHealthIcon}
//               alt="Women's Health"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Women's Health</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/mens-health">
//             <img
//               src={mensHealthIcon}
//               alt="Men's Health"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Men's Health</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/emerging-tech">
//             <img src={techIcon} alt="Emerging Tech" className="sidebar-icon" />
//             {!isMinimized && <span>Emerging Health Care Technologies</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/health-conditions">
//             <img
//               src={conditionsIcon}
//               alt="Health Conditions"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Health Conditions</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/sexual-health">
//             <img
//               src={sexualHealthIcon}
//               alt="Sexual Health"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Sexual Health</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/lifestyle-disorder">
//             <img
//               src={lifestyleIcon}
//               alt="Lifestyle Disorder"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Lifestyle Disorder</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/public-health">
//             <img
//               src={publicHealthIcon}
//               alt="Public Health"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Public Health & Safety</span>}
//           </Link>
//         </li>
//         <li className="sidebar-item">
//           <Link to="/blogs/childrens-care">
//             <img
//               src={childrenCareIcon}
//               alt="Children's Care"
//               className="sidebar-icon"
//             />
//             {!isMinimized && <span>Children's Care</span>}
//           </Link>
//         </li>
//       </ul>
//     </div>
//     </>
//   );
// }

// export default Sidebar;
