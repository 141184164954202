// AdminPanel.js
import React, { useState } from 'react';
// import imageToBase64 from 'image-to-base64/browser';
import AdminBlog from './Admin/AdminBlog';
import AdminAddNewBlog from './Admin/AdminAddNewBlog';

// import AdminBlog from "./Admin/AdminBlog";
// import AdminRemoveBlog from "./Admin/AdminRemoveBlog";
// import AdminAddNewBlog from "./Admin/AdminAddNewBlog"

const AdminPanel = ({ auth }) => {
    const [blogModalIsOpen, setBlogModalIsOpen] = useState(false);
    // State variables to hold form data
    // const openBlogInputModelFromBlogs = () => openBlogInputModel(true);
    const setBlogInput = (isVisible) => setBlogModalIsOpen(isVisible)
    return (
        <>
            {
                auth
                &&
                <>
                    <h1 style={{ textAlign: 'center', textShadow: '0 3px 2px #02bf34',margin:'0 36.5%' }}>Admin Dashborad</h1>
                    <AdminAddNewBlog modalIsOpen={blogModalIsOpen} setBlogInput={setBlogInput}  /*modalIsOpen={blogModalIsOpen} setModalIsOpen={setBlogModalIsOpen}*/ />
                    <AdminBlog setBlogInput={setBlogInput} />
                </>
            }
        </>
    )
}


export default AdminPanel;

