// AdminAddNewBlog.js
import axios from 'axios';
import React, { useState } from 'react';
// import imageToBase64 from 'image-to-base64/browser';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
// import imageCompression from 'browser-image-compression';

const AdminAddNewBlog = ({ modalIsOpen, setBlogInput }) => {
  // State variables to hold form data
  // const [modalIsOpen, setBlogInput] = useState(false);
  const [title, setTitle] = useState('');
  const [Sub_title, setDescription] = useState('');
  const [blog_img, setImage] = useState(null);
  const [writter, setWritter] = useState(null);
  const [category, setCategory] = useState(null);
  const [blog_description_1, setBlog_description_1] = useState(null);


  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!title || !Sub_title || !blog_img || !category || !writter) {
      toast.error("fill all title, description, category, writter and image something you have been missed detail");
      return 0
    }
    try {
      axios.post('https://api.healthsafe.in/blog/addNewBlog', { title: title, Sub_title: Sub_title, blog_img: blog_img, category:category,writter:writter, blog_description_1:blog_description_1 }) // Adjust URL to match your backend endpoint
        .then(response => {
          if (response.status === 200 || response.status === 'OK') {
            // navigate('/admin/admin-add-new-blog')
            toast.success('New Blog Added successfully')

            // console.log("response are => ", response);

            setTitle('');
            setDescription('');
            setImage('');
            window.location.href = "/admin/"
          } else toast.error("msg not sended")
        })
        .catch(error => toast.error(error))
    } catch (err) {
      toast.error(err);
    }
  };

  // Handle image file input change
  const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0]; // Get the uploaded file
    const fileSizeInBytes = file.size; // File size in bytes
    const fileSizeInKB = (fileSizeInBytes / 1024 /1024).toFixed(2); // Convert size to KB and round to 2 decimal places
    // console.log(`Image size: ${fileSizeInKB} MB`);
    if (Number(fileSizeInKB)<=1) {
      // console.log(`2 Image size: ${fileSizeInKB} MB`);
      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = () => {
        // console.log(reader.result)
        // console.log("image size url => ", reader)
        setImage(reader.result)
      }
      reader.onerror = err => console.log(err);
    }else{
      toast.info("image size should be less then 1 MB.")
    }
  };
  // const handleImageUpload = async (event) => {
  //   const file = event.target.files[0];

  //   // Options for compression
  //   const options = {
  //     maxSizeMB: 0.1,           // Max size in MB (adjust based on your needs)
  //     maxWidthOrHeight: 800,  // Max width or height in pixels (adjust as needed)
  //     useWebWorker: true,     // Use web worker for faster compression
  //   };

  //   try {
  //     // Compress the image file
  //     const compressedFile = await imageCompression(file, options);

  //     // Convert the compressed file to Base64 using FileReader
  //     console.log("compressedFile => ", compressedFile);
  //     const reader = new FileReader();
  //     reader.readAsDataURL(compressedFile);
  //     reader.onload = () => {
  //       console.log("reader.result => ", reader.result); // Compressed base64 string
  //       setImage(reader.result);    // Update state with compressed image URL
  //     };
  //     reader.onerror = (err) => console.error(err);
  //   } catch (error) {
  //     console.error('Error compressing the image:', error);
  //   }
  // }
  // const openBlogInputModel = (e) => setBlogInput(e||false);

  return (
    <>
      {/* <button onClick={() => setBlogInput(true)}>Add New Blog</button> */}
      <Modal isOpen={modalIsOpen} onRequestClose={() => setBlogInput(false)} style={{ position: 'relative', zIndex: 2 }} className="login-modal">
        <h2>Add New Blog</h2> {/*add blog Card*/}
        {/* <label>Category: </label> */}
        <input
          type="text"
          placeholder="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        {/* <label>Title: </label> */}
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* <label>Sub Title: </label> */}
        <input
          type="text"
          placeholder="Description"
          value={Sub_title}
          onChange={(e) => setDescription(e.target.value)}
        />
        {/* <label>Blog Image</label> */}
        <input
          accept='blog_img/*'
          type="file"
          placeholder="Upload Blog Image"
          onChange={handleImageUpload}
        />
        {/* <label>Article Description</label> */}
        <input
          type="text"
          placeholder="Article Description"
          value={blog_description_1}
          onChange={(e) => setBlog_description_1(e.target.value)}
        />
        {/* <label>Writter</label> */}
        <input
          type="text"
          placeholder="Author"
          value={writter}
          onChange={(e) => setWritter(e.target.value)}
        />
        <button onClick={handleSubmit}>Add Blog</button>
        <button onClick={() => setBlogInput(false)}>Close</button>
      </Modal>
    </>
  );
}




export default AdminAddNewBlog;

