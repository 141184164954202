import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './BlogPage.css';
// import vaccine from './assets/vaccine.jpg';
// import { toast } from 'react-toastify';
// import axios from 'axios';
import { BlogContext } from './BlogContext';

const BlogPage = () => {

  // const [blogs, setBlogs] = useState([]);
  const { blogs } = useContext(BlogContext);
  // const getAllBlogs = (e) => {
  //   // e.preventDefault();
  //   try {
  //     axios.get('/blog/') // Adjust URL to match your backend endpoint
  //       .then(response => {
  //         if (response.status === 200 || response.status === 'OK') {
  //           console.log("get all blogs response are => ", response);
  //           setBlogs(response.data.blog)
  //         } else toast.error("no Data found")
  //       })
  //       .catch(error => toast.error(error))
  //   } catch (err) {
  //     toast.error(err);
  //   }
  // }
  // const blogPosts = [
  //   {
  //     title: 'Immunization of children',
  //     description: 'Immunization of children: it is mandatory that the child should be fully immunized',
  //     image: vaccine,
  //     link: '/blog/health-checkups',
  //     author: 'Dr. John Smith',
  //     date: 'October 1, 2024',
  //   }
  // ];

  // useEffect(() => {
  //   getAllBlogs()
  // }, [])

  return (
    <div className="blog-page">
      <h1>Latest Blogs</h1>
      <div className="blog-grid">
        {blogs?.slice().reverse().map((post, index) => (
          <div key={index} className="blog-post">
            {/* <Link to={`/blog/${encodeURIComponent(post.title)}`} className="blog-link"> */}
            <Link to={`/blogs/${post._id}`} className="blog-link">
              <img src={post.blog_img} alt={post.title} />
              <h2>{post.title}</h2>
              <p>{post.Sub_title}</p>
              <i><p className="blog-author">{post.writter}</p></i>
              <i><p className="blog-date">{post.date.substring(0, 10)}</p></i>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
