import React, { useContext } from 'react';
import './BlogPage.css';
import { Link } from 'react-router-dom';
import { BlogContext } from '../BlogContext';


const BlogPage = () => {
  const { blogs } = useContext(BlogContext);

  const womensHealthBlogs = blogs.filter(blog => blog.category === "Women's Health");

  // const category = womensHealthBlogs.length > 0 ? womensHealthBlogs[0].category : 'General';

  return (
    <div className="blog-page">
      <h1>Women's Health Care</h1>
      <div className="blog-grid">
        {womensHealthBlogs.length === 0 ?
          <p className="coming-soon">
            Coming Soon...! Explore our <Link to="/blogs" className="explore-link">other blogs</Link> in the meantime.
          </p> :
          <>
            {
              womensHealthBlogs.map((post, index) => (
                <div key={index} className="blog-post">
                  <Link to={`/blogs/${post._id}`} className="blog-link">
                    <div className="blog-card">
                      <img src={post.blog_img} alt={post.title} />
                      <h4>{post.title}</h4>
                      <p className='sub-title'>{post.Sub_title}</p>
                      {/* <div dangerouslySetInnerHTML={{ __html: blog.Sub_title }} ></div> */}
                      <i><p className="blog-author">{post.writter}</p></i>
                      <i><p className="blog-date">{post.date.substring(0, 10)}</p></i>
                    </div>
                  </Link>
                </div>
              ))
            }</>
        }
      </div>
    </div>
  );
};

export default BlogPage;
