// import React from 'react';

// function AboutUs() {
//     return (
//         <div>
//             <h1>About Us</h1>
//             <p>Welcome to the About Us page of Safe Health.</p>
//         </div>
//     );
// }

// export default AboutUs;

// AboutUs.js
import React from 'react';
import './AboutUs.css';
import safeHealthAboutImg1 from './images/1.jpeg';
// import safeHealthAboutImg2 from './images/2.jpeg';
// import safeHealthAboutImg3 from './images/3.jpeg';
import safeHealthAboutImg4 from './images/4.jpeg';
import safeHealthAboutImg5 from './images/5.jpeg';
import safeHealthAboutImg6 from './images/6.jpg';
// import safeHealthAboutImg7 from './images/7.jpg';
import safeHealthAboutImg8 from './images/8.jpeg';
import logo from './assets/SafeHealth_footer.png';


const Header = () => (
    <header className="header">
        
    </header>
);

const Section = ({ title, children }) => (
    <section className="section">
        <h2>{title}</h2>
        {children}
    </section>
);

/*const About = () => (
    <div className="about-us">
        <Header />
        <main className="content">
            <Section title="What You Will Find Here">
                <p><strong>Expert Articles:</strong> Our content is crafted by healthcare professionals and medical students, ensuring you get accurate and up-to-date information.</p>
                <p><strong>Practical Health Tips:</strong> Simple, actionable advice to help you lead a healthier life.</p>
                <p><strong>Inspiring Stories:</strong> Real-life experiences shared by people who have successfully managed their health journeys.</p>
                <p><strong>Latest Trends:</strong> Stay informed on the newest developments in healthcare technology and innovations.</p>
            </Section>
        </main>

        <footer className="footer-div">
            <p>Connect With Us</p>
            <p>We love to hear from our readers! If you have questions, suggestions, or topics you'd like us to cover, feel free to reach out. Follow us on Instagram, Facebook, and Twitter for more updates and health tips.</p>
        </footer>
    </div>
);*/

function AboutUs() {
        return (
            <div className='aboutUs'>
                <div className='AboutSection'>
                    <div className='about-section-content'>
                    <h1 id="headline">Welcome to the Safe Health Blogs!</h1>
                    <p id="first_para" >
                       Welcome to Safe Health!
                          We'r thrilled to have you here,   
                        At safe Health, we're dedicated to reshaping the way you access reliable health information and empowering you to make well-informed
                         decisions for healtheir life.
                        Whether you're looking for expert-backed advice,health tips, or tools to support your wellness journey, you've come to the right palce.<br></br><br></br>
                        Co-Founded by a passionate team of Health and tech professional, Safe Health combines a seamless user experience with high-quality content designed to meet your health needs 
                        in a personalized, accessible, and trustworthy way. 
                         </p>
                    </div>  
                    <div className='about-section-image'><img src={logo} className="blogImage" />
                    </div>    
                </div>
                <div className='AboutSection'>
                    <div className='about-section-image'><img src={safeHealthAboutImg6} className="blogImage" /></div> 
                    <div className='about-section-content'>
                        <h1>Our Mission</h1>
                    <p id="second_para">At Safe Health,we believe that knowledge is power, especially when it comes to taking care of your health.<br></br> 
                   Our mission is simple yet impactful: to create a trusted, user-friendly platform 
                   where everyone can find accurate, actionable health information and tools to enhance their well-being.
                    We believe in proactive, preventative health solutions that keep you informed, engaged, and in control of your health journey.</p>
                    </div>
                </div>
                <div className='AboutSection'>
                    <div className='about-section-content'>
                    <h1>What we offer</h1>
                    <p id="third_para">Safe Health is more than just a health platform. We’re committed to providing a well-rounded health resource with 
                        features designed to support and enhance your wellness journey. Here’s what you’ll find:
                        <div class="third_para_feature">
                        <ul>
                            <li>
                                <strong>Expert-Curated Health Content:</strong>Our articles, guides, and resources are crafted in collaboration with healthcare professionals, 
                                ensuring that you receive accurate, trustworthy, and relevant information across a variety of health topics.
                            </li>
                            <li>
                                <strong>Interactive Health Tools:</strong>We offer a suite of user-friendly tools that empower you to track, understand, and improve your health. From fitness tracking to symptom checkers, our tools make it easier to stay informed and engaged with your wellness goals.
                            </li>
                            <li>
                                <strong>Timely Health Updates: </strong>Stay informed on the latest in health and wellness, with updates on medical breakthroughs, preventive health tips, and more. Our platform keeps you connected to what matters most in health, so you’re always in the know.
                            </li>
                        </ul>
                        </div>
                        </p>
                        </div>  
                    <div className='about-section-image'><img src={safeHealthAboutImg5} className="blogImage" />
                    </div>    
                </div>
                <div className='AboutSection'>
                    <div className='about-section-image'><img src={safeHealthAboutImg4} className="blogImage" /></div> 
                    <div className='about-section-content'>
                        <h1>The Safe Health Blogs </h1>
                    <p id="fourth_para">Our blog is your go-to hub for insightful, well-researched articles that cover a wide range of health topics, 
                        from nutrition, mental health, and fitness tips to the latest advancements in medical science. 
                        We’re committed to breaking down complex health information into easy-to-understand, 
                        actionable insights that support your well-being every step of the way. 
                        Each piece on the blog is crafted with you in mind—bringing you content that is relevant, accessible, 
                        and backed by expertise.</p>
                    </div>
                </div>
                <div className='AboutSection'>
                    <div className='about-section-content'>
                        <h1>What’s Next for Safe Health</h1>
                    <p id="fifth_para">We’re excited about the future and are constantly developing innovative features to make your health journey smoother and more empowered. 
                        Here’s a glimpse of what you can look forward to:
                        <ul>
                            <li>
                                <strong>Expanded Health Tools:</strong>From advanced tracking systems to novel ways of measuring and managing your health,
                                 we’re committed to providing tools that make health management more accessible and convenient.
                            </li>
                            <li>
                                <strong>Community Engagement</strong>We’re building features that connect you with others on similar health journeys,
                                 including forums, support groups, 
                                and expert-led Q&A sessions. Our goal is to foster a supportive community that inspires and empowers each member.
                            </li>
                        </ul>
                        </p>
                        </div>  
                    <div className='about-section-image'><img src={safeHealthAboutImg8} className="blogImage" />
                    </div>    
                </div>
                <div className='AboutSection'>
                <div className='about-section-image'><img src={safeHealthAboutImg1} className="blogImage" />
                </div>    
                    <div className='about-section-content'>
                    <h1>Connect With Us</h1>
                    <p id="sixth_para">We love to hear from our readers! If you have questions, suggestions, or topics you'd like us to cover, feel free to reach out. Follow us on Instagram, Facebook, and Twitter for more updates and health tips.</p>
                        </div>  
                   
                </div>
                {/* <Header/><Section/>
            <About/> */}
            </div>
        );
    }

export default AboutUs;